import {
  faBold,
  faImage,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faCode,
  faQuoteRight,
  faStrikethrough,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import CodeIcon from "@mui/icons-material/Code";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import { Editor, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { marked } from "marked";
import { ReactNode, useEffect } from "react";
import { useFlags } from "../context/FlagsContext/FlagsContext";
import { useSentry } from "../context/SentryContext";
import { sanitizeSnippet } from "../utils/generator";
import { useEditorUtils } from "./useEditorUtils";

const sanitizeConfig = {
  allowedTags: ["img", "blockquote"],
  allowedAttributes: {
    img: ["src", "alt"]
  },
  allowedSchemes: ["data", "https"]
};

export interface ToolbarButtonProps {
  onClick?: () => void;
  icon?: IconDefinition;
  label: string;
  customIcon?: ReactNode;
}

export type HeadingOption = {
  label: string;
  onClick: () => void;
};

export enum ButtonLabels {
  BOLD = "Bold",
  ITALIC = "Italic",
  STRIKE_THROUGH = "Strikethrough",
  HEADING = "Heading",
  BULLET_LIST = "Bullet List",
  ORDERED_LIST = "Ordered List",
  INSERT_IMAGE = "Insert Image",
  CODE_BLOCK = "Code Block",
  CODE = "Code",
  BLOCK_QUOTE = "Block Quote",
  INSERT_LINK = "Insert Link"
}

type EditorHookProps = {
  editor: Editor | null;
  editorToolbarButtons: ToolbarButtonProps[];
  headingOption: HeadingOption[];
};

const useCustomEditor = (
  onChange: (content: string) => void,
  initialContent?: string
): EditorHookProps => {
  const sentry = useSentry();
  const { flags } = useFlags();
  const { kbGenPublishProcess } = flags;

  const sanitizeHtml = marked.parse(
    sanitizeSnippet(sentry, "", sanitizeConfig, initialContent)
  );

  const editor = useEditor({
    content: kbGenPublishProcess ? sanitizeHtml : "",
    extensions: [
      StarterKit,
      Image.configure({
        inline: true
      }),
      Link
    ],
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    }
  });

  const {
    toggleBold,
    toggleItalic,
    setHeading,
    toggleBulletList,
    toggleOrderedList,
    insertImage,
    toggleCodeBlock,
    toggleCode,
    toggleBlockQuote,
    toggleStrike,
    setLink
  } = useEditorUtils(editor);

  const headingOption = Array.from(Array(6), (_, idx) => ({
    onClick: () => setHeading(idx + 1),
    label: `Heading ${idx + 1}`
  }));

  const editorToolbarButtons: ToolbarButtonProps[] = [
    { onClick: toggleBold, icon: faBold, label: ButtonLabels.BOLD },
    { onClick: toggleItalic, icon: faItalic, label: ButtonLabels.ITALIC },
    {
      onClick: toggleStrike,
      icon: faStrikethrough,
      label: ButtonLabels.STRIKE_THROUGH
    },
    { label: "Heading" },
    {
      onClick: toggleBulletList,
      icon: faListUl,
      label: ButtonLabels.BULLET_LIST
    },
    {
      onClick: toggleOrderedList,
      icon: faListOl,
      label: ButtonLabels.ORDERED_LIST
    },
    { onClick: insertImage, icon: faImage, label: ButtonLabels.INSERT_IMAGE },
    { onClick: toggleCodeBlock, icon: faCode, label: ButtonLabels.CODE_BLOCK },
    {
      onClick: toggleCode,
      customIcon: <CodeIcon className="code-icon" />,
      label: ButtonLabels.CODE
    },
    {
      onClick: toggleBlockQuote,
      icon: faQuoteRight,
      label: ButtonLabels.BLOCK_QUOTE
    },
    { onClick: setLink, icon: faLink, label: ButtonLabels.INSERT_LINK }
  ];

  useEffect(() => {
    if (editor && !kbGenPublishProcess) {
      editor.commands.setContent(sanitizeHtml);
    }
  }, [kbGenPublishProcess, sanitizeHtml, editor]);

  return {
    editor,
    editorToolbarButtons,
    headingOption
  };
};

export { useCustomEditor };
