import { CopyButton } from "@aptedge/lib-ui/src/components/CopyButton/CopyButton";
import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { LightTooltip } from "@aptedge/lib-ui/src/components/Tooltip/LightTooltip";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateAnswerCardVisibility } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import {
  updateSearchCardVisibility,
  updateShowBackButtonInPreview
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  SearchTelemetryEvent,
  ARTICLE_SOURCE_TYPE,
  QUERY_PARAMS,
  DOCUMENT_SOURCE_TYPE,
  FEDERATED_SOURCE_TYPE,
  SEARCH_RESULT_TYPE,
  GENERATED_KNOWLEDGE_SOURCE_TYPE
} from "@aptedge/lib-ui/src/types/entities";
import { dataLayerPush, GTM_EVENTS } from "@aptedge/lib-ui/src/utils/event";
import {
  calculatePosition,
  generateTopSearchResults
} from "@aptedge/lib-ui/src/utils/generator";
import { History } from "history";
import { FC } from "react";
import useTabHistory from "../../../../src/hooks/useTabHistory";
import { toEdgePath, toTicketPath } from "../../../../src/routes/toPath";
import useSearchResult from "./useSearchResult";

const PER_PAGE = 10;

enum PreviewActions {
  GO_BACK = "go_back",
  CLOSE = "close"
}

const SearchPreviewCardHeader: FC = () => {
  const {
    url,
    type,
    originalId,
    sourceType,
    icon,
    sourceId
  } = useSearchResult();
  const searchResultData = {
    id: originalId,
    displayUrl: url,
    type,
    sourceType,
    sourceId
  };
  const { tabHistory } = useTabHistory();
  const { flags } = useFlags();
  const { removeQueryParams } = useQueryParams();

  const {
    searchQuery,
    searchResults,
    totalSearchResults,
    page,
    showBackButtonInPreview
  } = useAppSelector((state) => state.search);

  const eventObj: SearchTelemetryEvent = {
    search_query: searchQuery,
    top_search_results: generateTopSearchResults(searchResults),
    total_search_results: totalSearchResults,
    search_result_position:
      searchResults.findIndex((result) => result.id === searchResultData.id) +
      1,
    page
  };

  const dispatch = useAppDispatch();

  const handlePreviewActions = (action: string): void => {
    removeQueryParams(QUERY_PARAMS.RESULT_ID);
    dispatch(updateSearchCardVisibility(false));
    if (action === PreviewActions.GO_BACK) {
      dispatch(updateAnswerCardVisibility(true));
      dispatch(updateShowBackButtonInPreview(false));
    }
  };

  const goBack = (): void => {
    handlePreviewActions(PreviewActions.GO_BACK);
  };

  const onClose = (): void => {
    handlePreviewActions(PreviewActions.CLOSE);
  };

  const getIntegrationNameFromType = (type: string): string => {
    switch (type) {
      case ARTICLE_SOURCE_TYPE.DOC360:
        return "Document360";
      case ARTICLE_SOURCE_TYPE.MADCAP_FLARE:
        return "MadCap Flare";
      case ARTICLE_SOURCE_TYPE.DOCUSAURUS:
        return "Docusaurus";
      case ARTICLE_SOURCE_TYPE.CX_ONE_EXPERT:
        return "CXone Expert";
      case FEDERATED_SOURCE_TYPE.FEDERATED:
        return "External";
      case GENERATED_KNOWLEDGE_SOURCE_TYPE.GENERATED_KNOWLEDGE:
        return "Generated Knowledge";
      default:
        return type;
    }
  };

  return (
    <div className="header_container">
      <div className="header">
        <div className="details">
          {flags.answerGptUiV2 && showBackButtonInPreview && (
            <Icons.ArrowLeft size="sm" onClick={goBack} />
          )}
          <span className="header-icon">{icon}</span>
          <span className="header-type">
            {getIntegrationNameFromType(sourceType ?? type)}
          </span>
        </div>
        <div className="header-action-icons">
          <LightTooltip title="Copy" placement="top" arrow>
            <div>
              <CopyButton
                text={url}
                className="copy-icon-preview-header"
                event={GTM_EVENTS.GTM_SEARCH_RESULT_PREVIEW_TEXT_COPIED}
              />
            </div>
          </LightTooltip>
          <LightTooltip title="Open In New Tab" placement="top" arrow>
            <div>
              <AeIcons.OpenLink
                className="linkIcon"
                onClick={(e) =>
                  onLinkIconClick(searchResultData, tabHistory, eventObj)
                }
              />
            </div>
          </LightTooltip>
          {!flags.answerGptUiV2 && (
            <button className="close-icon-preview-header" onClick={onClose}>
              <AeIcons.CloseIcon />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const onLinkIconClick = (
  searchResult: {
    id: number | string;
    displayUrl: string;
    type: string;
    sourceType?: string;
    sourceId?: string;
  },
  tabHistory: History<History.PoorMansUnknown>,
  eventObj: SearchTelemetryEvent
): void => {
  const search_result_position_computed = eventObj.search_result_position
    ? eventObj.search_result_position
    : 0;
  dataLayerPush({
    event: GTM_EVENTS.GTM_SEARCH_RESULT_PREVIEW_POPOUT,
    data: {
      search_result_type: searchResult.type,
      search_result_id: searchResult.id,
      search_query: eventObj.search_query,
      total_search_results: eventObj.total_search_results,
      top_search_results: eventObj.top_search_results,
      integration_type: searchResult.sourceType,
      search_result_position: calculatePosition(
        search_result_position_computed,
        eventObj.page,
        PER_PAGE
      ),
      search_result_position_on_page: search_result_position_computed,
      page: eventObj.page
    }
  });
  switch (searchResult.type) {
    case SEARCH_RESULT_TYPE.EDGE:
      tabHistory.push(toEdgePath(Number(searchResult.id)));
      break;
    case SEARCH_RESULT_TYPE.TICKET:
      tabHistory.push(toTicketPath(Number(searchResult.id)));
      break;
    case SEARCH_RESULT_TYPE.DOCUMENT:
      if (searchResult.sourceType === DOCUMENT_SOURCE_TYPE.EDGE)
        tabHistory.push(toEdgePath(Number(searchResult.sourceId)));
      else window.open(searchResult.displayUrl, "_blank");
      break;
    default:
      if ("displayUrl" in searchResult)
        window.open(searchResult.displayUrl, "_blank");
      break;
  }
};
export default SearchPreviewCardHeader;
