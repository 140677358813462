import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import useModal from "@aptedge/lib-ui/src/hooks/useModal/useModal";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updatePage } from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import { QUERY_PARAMS } from "@aptedge/lib-ui/src/types/entities";
import { FC, useEffect } from "react";
import {
  PaginationAlignment,
  PaginationBar
} from "../../components/PaginationBar/PaginationBar";
import styles from "./ArticlesTable.module.scss";
import { ArticlesTableHeader } from "./ArticlesTableHeader";
import { ArticlesTableItem } from "./ArticlesTableItem";
import { ViewArticleModal } from "./ViewArticleModal";

interface ArticlesTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  articles: any;
}

const ArticlesTable: FC<ArticlesTableProps> = ({ articles }) => {
  const { flags } = useFlags();
  const { modalVisibility, closeModal, modalRef } = useModal();
  const { queryParams, updateParams, clearAllQueryParams } = useQueryParams();
  const currentArticleId = queryParams.get(QUERY_PARAMS.ARTICLE_ID);

  const dispatch = useAppDispatch();

  const { page, totalPages } = useAppSelector((state) => state.knowledgeBase);

  const handlePaginationChange = ({ page }: { page: number }): void => {
    updateParams(QUERY_PARAMS.PAGE, page.toString());
    dispatch(updatePage(page));
  };

  const handleCloseModal = (): void => {
    clearAllQueryParams();
    closeModal(false);
  };

  useEffect(() => {
    if (currentArticleId) {
      closeModal(true);
    }
  }, [currentArticleId, closeModal]);

  return (
    <div className={styles.articlesTableContainer}>
      <ArticlesTableHeader />
      {articles.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (article: any, idx: number) => (
          <ArticlesTableItem key={article.id} article={article} idx={idx} />
        )
      )}
      {modalVisibility && currentArticleId && (
        <ViewArticleModal
          ref={modalRef}
          articleId={currentArticleId}
          handleClose={handleCloseModal}
        />
      )}
      {Boolean(articles && articles.length > 0) &&
        flags.kbGenPublishProcess && (
          <PaginationBar
            page={page}
            totalPages={totalPages}
            onChange={handlePaginationChange}
            align={PaginationAlignment.CENTER}
            className={styles.articlePagination}
          />
        )}
    </div>
  );
};

export { ArticlesTable };
