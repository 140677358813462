import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tiptap/react";
import { FC } from "react";
import { HeadingOption } from "../../hooks/useCustomEditor";
import { Dropdown } from "../Dropdown/Dropdown";
import { Icon } from "../Icon/Icon";

type HeadingDropdownProps = {
  options: HeadingOption[];
  editor: Editor | null;
};

const HeadingDropdown: FC<HeadingDropdownProps> = ({ options, editor }) => {
  return (
    <Dropdown.Container placement="bottom-start">
      <Dropdown.Toggle>
        <div className="heading">
          <span>
            {options.find((_, index) =>
              editor?.isActive("heading", { level: index + 1 })
            )?.label || "Text Style"}
          </span>
          <Icon icon={faChevronDown} size="xs" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item key={option.label} onClick={option.onClick}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown.Container>
  );
};

export { HeadingDropdown };
