import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useSearchPreview } from "@aptedge/lib-ui/src/hooks/useSearchPreview";
import {
  IDocument,
  DOCUMENT_SOURCE_TYPE,
  ICompositeResult
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { ReactNode } from "react";
import SearchCardTimings from "../SearchCardTimings/SearchCardTimings";
import SearchResultCardActionIcons from "../SearchResultCardActionIcons/SearchResultCardActionIcons";
import { SearchResultPreview } from "../SearchResultPreview/SearchResultPreview";

import "./DocumentSearchResult.scss";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    "ref"
  > {
  className?: string;
  document: IDocument;
  isSelected?: boolean;
  showActionIcons?: boolean;
}

const DocumentSearchResult: React.FC<Props> = ({
  className,
  document,
  isSelected,
  showActionIcons,
  ...htmlProps
}) => {
  const { flags } = useFlags();
  const { hasPreview, previewSnippet, previewType } = useSearchPreview({
    ...document,
    type: "document"
  });

  const getDocumentIcon = (): ReactNode => {
    const sourceType = document?.sourceType;
    switch (sourceType) {
      case DOCUMENT_SOURCE_TYPE.EDGE:
        return <AeIcons.EdgeSR className="mr-3 p-0" />;
      case DOCUMENT_SOURCE_TYPE.SHAREPOINT_DRIVE:
        return <AeIcons.Sharepoint className="mr-3 p-0" />;
      default:
        return <AeIcons.PdfIcon className="mr-3 p-0" />;
    }
  };

  return (
    <div
      {...htmlProps}
      className={classNames("document-search-result", className, {
        selectable: !!htmlProps.onClick,
        active: isSelected,
        "document-search-result-v1": !flags.answerGptUiV2
      })}
    >
      <div>
        <div className="document-header">
          {getDocumentIcon()}
          <span className="subject">
            <SearchResultPreview
              previewSnippet={
                hasPreview && previewType === "title"
                  ? previewSnippet
                  : document.title
              }
            />
          </span>
          {showActionIcons && (
            <div className="document-header-action-icons">
              <SearchResultCardActionIcons
                searchResult={document as ICompositeResult}
              />
            </div>
          )}
        </div>
        {hasPreview && previewType === "body" && (
          <div className="document-description">
            <SearchResultPreview previewSnippet={previewSnippet} />
          </div>
        )}
      </div>
      <SearchCardTimings
        createdOn={document.createdOn}
        updatedOn={document.updatedOn}
      />
    </div>
  );
};

export { DocumentSearchResult };
