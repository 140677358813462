import { FC } from "react";
import "./Header.scss";
import { ActionButtons } from "../ActionButtons/ActionButtons";

const Header: FC = () => {
  return (
    <div className="header-container">
      <span className="title">Answer AI Reply</span>
      <ActionButtons />
    </div>
  );
};

export default Header;
